import React from "react";
import { Page } from "../components/Page";
import { Column, Row } from "@react-tiny-grid/core";

export function GuidesPage({ width, guides, onGuideClicked }) {
  return (
    <Page>
      <Row breakpoints={[300, 375]} spacing={[0, 0]}>
        {guides.map((guide) => (
          <Column key={guide.id} widths={[12, 6]}>
            <GuideCell
              {...{ width, guide }}
              onClick={() => onGuideClicked(guide)}
            />
          </Column>
        ))}
      </Row>
    </Page>
  );
}

function GuideCell({ guide, onClick }) {
  return (
    <div
      {...{ onClick }}
      style={{
        marginLeft: 8,
        marginRight: 8,
        marginBottom: 16,
        cursor: "pointer",
        position: "relative",
      }}
    >
      <img
        alt={guide.name}
        src={guide.media.url}
        style={{ width: "100%", borderRadius: 16 }}
      />

      <div
        style={{
          background:
            "linear-gradient(to bottom,  rgba(0,0,0,0) 0%,rgba(0,0,0,0.2) 100%)",

          top: 0,
          left: 0,
          right: 0,
          bottom: 3,
          borderRadius: 16,
          position: "absolute",
          border: "0px solid red",
        }}
      />
      <h1
        style={{
          bottom: 8,
          paddingLeft: 16,
          paddingRight: 16,

          fontSize: 18,
          color: "#fff",
          fontFamily: "Nanum Myeongjo",
          position: "absolute",
        }}
      >
        {guide.name}
      </h1>
    </div>
  );
}
