import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";

import r2wc from "@r2wc/react-to-web-component";

const Guidepal = r2wc(App, React, ReactDOM, {
  props: {
    ids: "string",
  },
});

customElements.define("guidepal-widget", Guidepal); /*
const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
/**/
