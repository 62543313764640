import GoogleMapReact from "google-map-react";
import React from "react";
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
} from "react-google-maps";

export function GuideMap({ width, guide, onPlace }) {
  return (
    <MapView
      {...{ width, onPlace }}
      googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyBpVPUR8JmwhJbtpl0aJss45Jn-Hy5Xwv4&v=3.exp&libraries=geometry,drawing,places"
      loadingElement={<div style={{ height: `100%`, width: 300 }} />}
      containerElement={<div style={{ height: `400px`, width: 300 }} />}
      mapElement={<div style={{ height: `100%`, width: 300 }} />}
      location={guide.location}
      places={guide.places.map((place, index) => ({ ...place, index }))}
    />
  );
}

export function PlaceMap({ width, place, onPlace }) {
  return (
    <MapView
      googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyBpVPUR8JmwhJbtpl0aJss45Jn-Hy5Xwv4"
      loadingElement={<div style={{ height: `100%`, width: 300 }} />}
      containerElement={<div style={{ height: `400px`, width: 300 }} />}
      mapElement={<div style={{ height: `100%`, width: 300 }} />}
      places={[place]}
      {...{ width, onPlace }}
      location={place.location}
    />
  );
}

export const MapView1 = withScriptjs(
  withGoogleMap((props) => (
    <GoogleMap
      {...props}
      defaultZoom={8}
      defaultCenter={{ lat: -34.397, lng: 150.644 }}
    >
      {props.isMarkerShown && (
        <Marker position={{ lat: -34.397, lng: 150.644 }} />
      )}
    </GoogleMap>
  ))
);

export function MapView({ width, location, places, onPlace }) {
  const [markers, setMarkers] = React.useState(
    (places || [])
      .filter((place) => place.location)
      .map((place) => ({ ...place, time: Date.now() }))
  );
  const region = {
    ...location,
    latitudeDelta: 0.0922,
    longitudeDelta: 0.0421,
  };

  const defaultProps = {
    center: {
      lat: location?.latitude,
      lng: location?.longitude,
    },
    zoom: 11,
  };

  return (
    <div
      style={{
        borderRadius: 16,
        marginLeft: 10,
        marginRight: 10,
        marginBottom: 24,
        width: width - 36,
        height: width * 0.6,
        overflow: "hidden",
      }}
    >
      <GoogleMapReact
        region={region}
        // options={{ styles: mapStyle }}
        defaultZoom={defaultProps.zoom}
        defaultCenter={defaultProps.center}
        onChange={() => {
          console.log("update markers");
          setMarkers((places || []).filter((place) => place.location));
        }}
        bootstrapURLKeys={{ key: "AIzaSyBpVPUR8JmwhJbtpl0aJss45Jn-Hy5Xwv4" }}
      >
        {markers.map((place) => (
          <MapMarker
            key={place.id}
            index={place.index}
            onClick={() => onPlace(place)}
            lat={place.location?.latitude}
            lng={place.location?.longitude}
          />
        ))}
      </GoogleMapReact>
    </div>
  );
}

const MapMarker = ({ index, onClick }) => (
  <div
    onClick={onClick}
    style={{
      width: 48,
      height: 48,
      fontSize: 22,
      color: "#fff",
      borderRadius: 24,
      textAlign: "center",
      position: "relative",
      backgroundColor: "#908DD8",
      verticalAlign: "middle",
    }}
  >
    <div
      style={{
        left: 12,
        width: 24,
        height: 24,
        bottom: -5,
        position: "absolute",
        backgroundColor: "#908DD8",
        transform: "rotate(45deg)",
      }}
    />

    <div
      style={{
        width: 48,
        paddingTop: 12,
        textAlign: "center",
        position: "absolute",
      }}
    >
      {index + 1}
    </div>
  </div>
);

const mapStyle = [
  {
    featureType: "all",
    elementType: "labels",
    stylers: [
      {
        visibility: "on",
      },
    ],
  },
  {
    featureType: "all",
    elementType: "labels.text.fill",
    stylers: [
      {
        saturation: 36,
      },
      {
        color: "#000000",
      },
      {
        lightness: 40,
      },
    ],
  },
  {
    featureType: "all",
    elementType: "labels.text.stroke",
    stylers: [
      {
        visibility: "on",
      },
      {
        color: "#000000",
      },
      {
        lightness: 16,
      },
    ],
  },
  {
    featureType: "all",
    elementType: "labels.icon",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "administrative",
    elementType: "geometry.fill",
    stylers: [
      {
        color: "#000000",
      },
      {
        lightness: 20,
      },
    ],
  },
  {
    featureType: "administrative",
    elementType: "geometry.stroke",
    stylers: [
      {
        color: "#000000",
      },
      {
        lightness: 17,
      },
      {
        weight: 1.2,
      },
    ],
  },
  {
    featureType: "administrative.country",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#df3dbb",
      },
    ],
  },
  {
    featureType: "administrative.locality",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#c4c4c4",
      },
    ],
  },
  {
    featureType: "administrative.neighborhood",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#ffffff",
      },
    ],
  },
  {
    featureType: "landscape",
    elementType: "geometry",
    stylers: [
      {
        color: "#000000",
      },
      {
        lightness: 20,
      },
    ],
  },
  {
    featureType: "poi",
    elementType: "geometry",
    stylers: [
      {
        color: "#000000",
      },
      {
        lightness: 21,
      },
      {
        visibility: "on",
      },
    ],
  },
  {
    featureType: "poi.business",
    elementType: "geometry",
    stylers: [
      {
        visibility: "on",
      },
    ],
  },
  {
    featureType: "road.highway",
    elementType: "geometry.fill",
    stylers: [
      {
        color: "#de72bd",
      },
      {
        lightness: "0",
      },
    ],
  },
  {
    featureType: "road.highway",
    elementType: "geometry.stroke",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "road.highway",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#ffffff",
      },
    ],
  },
  {
    featureType: "road.highway",
    elementType: "labels.text.stroke",
    stylers: [
      {
        color: "#df3dbb",
      },
    ],
  },
  {
    featureType: "road.arterial",
    elementType: "geometry",
    stylers: [
      {
        color: "#000000",
      },
      {
        lightness: 18,
      },
    ],
  },
  {
    featureType: "road.arterial",
    elementType: "geometry.fill",
    stylers: [
      {
        color: "#575757",
      },
    ],
  },
  {
    featureType: "road.arterial",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#ffffff",
      },
    ],
  },
  {
    featureType: "road.arterial",
    elementType: "labels.text.stroke",
    stylers: [
      {
        color: "#2c2c2c",
      },
    ],
  },
  {
    featureType: "road.local",
    elementType: "geometry",
    stylers: [
      {
        color: "#000000",
      },
      {
        lightness: 16,
      },
    ],
  },
  {
    featureType: "road.local",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#999999",
      },
    ],
  },
  {
    featureType: "transit",
    elementType: "geometry",
    stylers: [
      {
        color: "#000000",
      },
      {
        lightness: 19,
      },
    ],
  },
  {
    featureType: "water",
    elementType: "geometry",
    stylers: [
      {
        color: "#000000",
      },
      {
        lightness: 17,
      },
    ],
  },
];
