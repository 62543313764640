import { Button } from "@mui/material";
import React from "react";
import CloseIcon from "@mui/icons-material/ChevronLeft";

export function Page({ children, onBack }) {
  return (
    <div>
      {onBack ? (
        <Button
          size="small"
          onClick={onBack}
          aria-label="close"
          startIcon={<CloseIcon />}
          sx={{
            top: 8,
            left: 8,
            backgroundColor: "#ebebeb",
            position: "absolute",
            borderRadius: 16,
            paddingLeft: 1,
            paddingRight: 2,
            color: "#555",
          }}
        >
          Back
        </Button>
      ) : null}
      {children}
    </div>
  );
}
