import { Column, Row } from "@react-tiny-grid/core";
import React from "react";
import { Page } from "../components/Page";
import { PlacePage } from "./PlacePage";
import { GuideMap } from "./maps";
import { Avatar, Grid } from "@mui/material";
import PlaceIcon from "@mui/icons-material/Place";
const fallback = "http://s3.amazonaws.com/gajdavatar/dummyAvatar.jpg";

export function GuidePage({ width, guide, onBack }) {
  const [place, setPlace] = React.useState(null);

  const refPage = React.useRef();

  const [dimensions, setDimensions] = React.useState({
    width: 0,
    height: 0,
  });

  React.useEffect(() => {
    if (refPage.current) {
      setDimensions({
        width: refPage.current.offsetWidth + 20,
        height: refPage.current.offsetHeight,
      });
    }
  }, []);

  if (place) {
    return (
      <PlacePage
        {...{ place }}
        width={dimensions.width}
        onBack={() => setPlace(null)}
      />
    );
  }

  const size = (dimensions.width - 48) / 3;

  return (
    <Page {...{ onBack }}>
      <div
        style={{
          marginTop: 16,
          width: width - 16,
          cursor: "pointer",
          position: "relative",
        }}
      >
        <img
          ref={refPage}
          alt={guide.name}
          src={guide.media.url}
          style={{
            width: "calc(100% - 16px)",
            borderRadius: 16,
            marginLeft: 8,
            marginRight: 8,
          }}
        />

        <div
          style={{
            background:
              "linear-gradient(to bottom, rgba(0,0,0,0) 0%, rgba(0,0,0,0.2) 100%)",

            top: 0,
            left: 8,
            right: 8,
            bottom: 3,
            borderRadius: 16,
            position: "absolute",
            border: "0px solid red",
          }}
        />
        <div
          style={{
            bottom: 32,
            paddingLeft: 24,
            paddingRight: 24,
            position: "absolute",
          }}
        >
          <h1 style={{ color: "#fff", fontSize: 26, marginBottom: 8 }}>
            {guide.name}
          </h1>

          <AddressView address={guide.address} />
          <UserView guide={guide} />
        </div>
      </div>
      <div
        style={{
          lineHeight: "26px",
          backgroundColor: "#f5f5f5",
          padding: 16,
          borderRadius: 16,
          marginTop: 16,
          marginLeft: 8,
          marginRight: 8,
        }}
      >
        {guide.description}
      </div>
      <div style={{ marginTop: 16, paddingLeft: 8 }}>Places</div>
      <div style={{ border: "0px solid red", padding: 4 }}>
        <Row breakpoints={[300, 375]} spacing={[0, 0]}>
          {guide.places.map((place, index) => (
            <Column key={place.id} widths={[6, 4]}>
              <PlaceCell
                {...{ size, place }}
                onClick={() => setPlace({ ...place, index })}
              />
            </Column>
          ))}
        </Row>
      </div>

      <GuideMap
        {...{ guide }}
        width={dimensions.width + 16}
        onPlace={(place) => setPlace(place)}
      />
    </Page>
  );
}

function PlaceCell({ size, place, onClick }) {
  let url = "";

  if (place && place.media) {
    url = place.media.url;
  } else if (place && place.medias && place.medias.length > 0) {
    url = place.medias[0].url;
    if (url.includes("mp4")) {
      place.medias
        .filter((media) => media.type === "video")
        .map((media) => (url = media.thumbnailUrl));
    }
  }

  return (
    <div {...{ onClick }} style={{ margin: 4, cursor: "pointer" }}>
      {url ? (
        <img
          src={url}
          alt={place.name}
          style={{ width: size, height: size, borderRadius: 16 }}
        />
      ) : null}

      <p style={{ marginTop: 0 }}>{place.name}</p>

      {false &&
        (place.medias || []).map((media) => {
          return (
            <img
              src={media.url}
              alt={media.text}
              style={{ width: "100%", borderRadius: 16 }}
            />
          );
        })}
    </div>
  );
}

export function UserView({ guide }) {
  return (
    <div
      style={{
        color: "#fff",
        marginTop: 4,
        textDecoration: "none",
        justifyContent: "center",
      }}
    >
      <Grid container alignItems="center">
        <Grid item>
          <Avatar
            alt={guide.user.username}
            sx={{ width: 36, height: 36 }}
            src={guide.user.media ? guide.user.media.url : fallback}
          />
        </Grid>
        <Grid item>
          <div
            style={{
              fontSize: 12,
              marginLeft: 7,
              display: "flex",
              fontWeight: "500",
              lineHeight: "16px",
            }}
          >
            In the footsteps of <br />
            {guide.user.name}
          </div>
        </Grid>
      </Grid>
    </div>
  );
}

export function AddressView({ address }) {
  return (
    <Grid container alignItems="center">
      <PlaceIcon sx={{ color: "#fff", fontSize: 18 }} />
      <Grid item>
        <div
          color="#fff"
          style={{ fontSize: 12, marginLeft: 4, color: "#fff" }}
        >
          {address}
        </div>
      </Grid>
    </Grid>
  );
}
