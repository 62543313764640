import React from "react";
import { Page } from "../components/Page";
import { PlaceMap } from "./maps";

function navigateToDirections(destinationLat, destinationLng) {
  const destination = `${destinationLat},${destinationLng}`;
  const directionLink = `https://www.google.com/maps/dir/?api=1&destination=${destination}`;

  // Open a new browser window or tab with the directions URL
  window.open(directionLink);
}

export function PlacePage({ width, place, onBack }) {
  React.useEffect(() => {
    if (place) {
      setTimeout(() => {
        window.scrollTo(10, 0);
      }, 2000);
    }
  }, [place]);

  function onPlace(place) {
    navigateToDirections(place.location.latitude, place.location.longitude);
  }

  return (
    <Page {...{ onBack }}>
      <h1>{place.name}</h1>
      {(place.medias || []).map((media) => (
        <MediaView key={media.id} {...{ width, media }} />
      ))}

      <PlaceMap {...{ width: width + 16, place, onPlace }} />
    </Page>
  );
}

function MediaView({ width, media }) {
  const mediaWidth = width - 0;
  if (media.type === "video") {
    return (
      <video
        style={{ borderRadius: 16, marginTop: 16, marginBottom: 16 }}
        width={mediaWidth}
        height={mediaWidth / media.ratio}
        controls
      >
        <source src={media.url} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    );
  }

  return (
    <div style={{ marginBottom: 32 }}>
      <img
        src={media.url}
        alt={media.text}
        style={{ width: "100%", borderRadius: 16 }}
      />
      {media.text ? <div>{media.text}</div> : null}
    </div>
  );
}
