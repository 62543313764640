import CloseIcon from "@mui/icons-material/Close";
import Dialog from "@mui/material/Dialog";
import IconButton from "@mui/material/IconButton";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import * as React from "react";
import { GuidePage } from "../pages/GuidePage";
import Root from "./Root";
import Size from "./Size";

export default function AlertDialog({ guide, onClose }) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const dialogRef = React.useRef(null);
  const [dialogWidth, setDialogWidth] = React.useState(0);

  React.useEffect(() => {
    if (dialogRef.current) {
      const width = dialogRef.current.getBoundingClientRect().width;

      console.log("dialogWidth", width);
      setDialogWidth(width);
    }
  }, []);

  return (
    <Dialog ref={dialogRef} fullScreen={fullScreen} open onClose={onClose}>
      <IconButton
        size="small"
        onClick={onClose}
        aria-label="close"
        sx={{
          top: 8,
          right: 8,
          backgroundColor: "#ebebeb",
          position: "absolute",
          color: "#555",
        }}
      >
        <CloseIcon />
      </IconButton>

      <div style={{ marginTop: 24, padding: 8 }}>
        <GuidePage width={dialogWidth} {...{ guide }} />
      </div>
    </Dialog>
  );
}
