import { request, gql } from "graphql-request";
import React from "react";
import { GuidesPage } from "./pages/GuidesPage";
import { GuidePage } from "./pages/GuidePage";
import { Modal } from "@mui/base/Modal";
import AlertDialog from "./components/AlertDialog";
import Root from "./components/Root";

// const serverUrl = "http://192.168.1.217:4000"; /*
const serverUrl = "https://guidepalserver.herokuapp.com/";
/**/

const document = gql`
  query {
    popularGuides {
      id
      name
      address
      description
      user {
        name
        media {
          url
        }
      }
      media {
        url
      }
      location {
        latitude
        longitude
      }
      places {
        name
        media {
          url
          ratio
        }
        medias {
          url
          text
          type
          ratio
        }
        location {
          latitude
          longitude
        }
      }
    }
  }
`;

function useGuides() {
  const [guides, setGuides] = React.useState([]);

  React.useEffect(() => {
    request({
      url: serverUrl,
      document,
      variables: {},
      requestHeaders: {},
    })
      .then((response) => {
        setGuides(response.popularGuides.slice(0, 4));
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);

  return guides;
}

const useDialog = true;

export default function App({ ids }) {
  const guides = useGuides();
  const [guide, setGuide] = React.useState(null);

  return (
    <Root>
      {(width) => {
        if (guide) {
          if (useDialog) {
            return (
              <AlertDialog
                {...{ width, guide }}
                onClose={() => setGuide(null)}
              />
            );
          }

          return (
            <GuidePage {...{ width, guide }} onBack={() => setGuide(null)} />
          );
        }

        return <GuidesPage {...{ width, guides }} onGuideClicked={setGuide} />;
      }}
    </Root>
  );
}
