import React from "react";

export default function Root({ children }) {
  const refPage = React.useRef();
  const [dimensions, setDimensions] = React.useState({
    width: 0,
    height: 0,
  });
  React.useEffect(() => {
    if (refPage.current) {
      setDimensions({
        width: refPage.current.offsetWidth,
        height: refPage.current.offsetHeight,
      });
    }
  }, []);

  return (
    <div
      ref={refPage}
      style={{
        maxWidth: 600,
        marginLeft: "auto",
        marginRight: "auto",
        border: "0px solid red",
      }}
    >
      {dimensions.width > 0 ? children(dimensions.width) : null}
    </div>
  );
}
